<template>
	<errorContainer :error="erreur" :warning="warning">
		<div v-if="$screen.width <= 576" class="animated fadeIn">
			<div class="container-mobile" v-if="currentExpenseMobile">
				<div class="my-expense-mobile">
					<div class="title">
						<h1 class="main-page-title" :class="{ 'is-pwa': $isPwa() }">
							{{ FormMSG(7, 'Expense details') }}
						</h1>
					</div>
				</div>
				<div class="back-with-title cursor-pointer" :class="{ 'mt-16': $isPwa() }" @click="goBack()">
					<div class="icon"><arrow-left color="#667987" :size="22" /></div>
					<h2>#{{ currentExpenseMobile.id }}</h2>
				</div>
				<div class="card has-margin-bottom mt-16">
					<div class="wrapper">
						<div class="header">
							<div class="first-col">
								<span>
									{{ dateToLiteral(currentExpenseMobile.date) }}
								</span>
							</div>
							<div class="second-col" v-if="!isFilmSingle">
								<div :class="`status ${currentExpenseMobile.statusClass}`">
									<span>
										{{ currentExpenseMobile.validatedStatus }}
									</span>
								</div>
							</div>
						</div>
						<div class="content">
							<div class="expense-title mt-3" v-if="showExpenseTitle(currentExpenseMobile)">
								{{ currentExpenseMobile.description }}
							</div>
							<div class="comment mt-3" v-if="currentExpenseMobile.comment && currentExpenseMobile.comment.length > 0">
								<div class="pl-2"><alert-circle size="20" /></div>
								<div class="px-2">{{ currentExpenseMobile.comment }}</div>
							</div>
							<div class="row-cols mt-3">
								<div class="third-col">
									<span class="label">{{ currentExpenseMobile.typeString }}</span>
									<span class="title">
										<span>
											<ticket color="#5B6164D6" :size="16" v-if="currentExpenseMobile.type === 0" />
											<!-- ticket -->
											<file-text color="#5B6164D6" :size="14" v-if="currentExpenseMobile.type === 1" />
											<!-- invoice -->
											<car color="#5B6164D6" :size="16" v-if="currentExpenseMobile.type === 2" />
											<!-- mileage -->
										</span>
									</span>
								</div>
								<div class="first-col">
									<span class="label">{{ convertToCurrency(currentExpenseMobile.amountTotal) }}</span>
									<span class="title">{{ FormMSG(8, 'Total amount') }}</span>
								</div>
								<div class="second-col">
									<span class="label">{{ convertToCo2(currentExpenseMobile.kgCoTwo) }}</span>
									<span class="title"
										><div class="hack-top-3"><sprout color="#47C7BF" :size="12" /></div>
										<div class="ml-1">CO<sub>2</sub></div></span
									>
								</div>
							</div>
						</div>
					</div>
					<div class="expense-detail-actions pt-2">
						<div v-if="!isFilmSingle">
							<div class="expense-detail-download">
								<button type="button" @click="downloadXls">
									<b-spinner class="mr-2" v-show="isLoadingDownload" />
									<span>{{ FormMSG(9, 'Download') }}</span>
									<span class="pl-2"><download :size="14" /></span>
								</button>
							</div>
							<div class="expense-detail-send-me pl-3">
								<button type="button" @click="sendXlsByEmail">
									<b-spinner class="mr-2" v-show="isLoadingSendMail" />
									<span>{{ FormMSG(10, 'Send me') }}</span>
									<span class="pl-2"><mail :size="14" /></span>
								</button>
							</div>
						</div>
						<div v-else>
							<div class="expense-detail-download">
								<share-information
									:btn-label="FormMSG(59, 'Share invoice')"
									:title-modal="FormMSG(60, 'Share the invoice expense to :')"
									:send-a-copy="true"
									:current-expense="currentExpenseMobile"
									:is-send-invoice="true"
									name-modal="modal-share-invoice-expenses"
									icon="fa fa-file-text"
									variant-btn="warning"
									style="margin-right: 5px"
									:isDisabled="!isFilmSinglePaying"
								/>
							</div>
							<div class="expense-detail-send-me pl-3">
								<share-information
									:btn-label="FormMSG(169, 'Share')"
									:title-modal="FormMSG(170, 'Send this expense sheet to :')"
									:send-a-copy="true"
									:current-expense="currentExpenseMobile"
									name-modal="modal-share-expenses"
								/>
							</div>
						</div>
					</div>
					<div class="card-inside-card" v-if="toggleDetail">
						<card-list-builder
							:use-new-version="true"
							:items="ExpItems"
							:fields="typeMobileFields"
							:header-fields="ticketMobileFields"
							:has-badge="true"
							:is-badge-use-props="true"
							badge-class="statusClass"
							badge-value="validatedStatus"
							:hide-status="isFilmSingle"
							:has-initial-data="ExpItems && ExpItems.length > 0"
							key-for-title="description"
							:toggle-mode="false"
						>
							<template slot="actions" slot-scope="{ item }">
								<!-- picture -->
								<photo
									class="action cursor-pointer"
									:size="20"
									@click="showPicture(item.images)"
									v-if="item.images && item.images.length > 0"
								/>
								<!-- add comment -->
								<message-circle
									class="action cursor-pointer"
									:size="20"
									color="#EA4E2C"
									@click="editComment(item.comment)"
									v-if="canReadComment"
								/>
								<!-- update expense -->
								<edit class="action cursor-pointer" :size="18" @click="editExpenseMobile(item)" v-if="showEditExpenseMobile(item)" />
							</template>
						</card-list-builder>
					</div>
					<!-- <expense-detail-footer @expense-detail-builder:toggle="toggleExpenseDetail" /> -->
				</div>
			</div>
		</div>
		<div class="animated fadeIn form" v-else>
			<b-row>
				<b-col md="12">
					<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">{{ FormMSG(7, 'Expense details') }}</h1>
				</b-col>
			</b-row>
			<div v-if="ExpItems && ExpItems.length > 0" class="container-layout details-document-package p-0">
				<b-row class="mt-0">
					<b-col>
						<b-card no-body class="card-border-blue-light">
							<b-row class="back-with-title cursor-pointer d-flex align-items-center pb-2 pl-0" :class="{ 'mt-16': $isPwa() }">
								<b-col cols="6">
									<button class="btn-transparent" @click="backToExpense">
										<component :is="getLucideIcon('ArrowLeft')" color="rgba(6, 38, 62, 0.65)" :size="22" class="icon" />
										<span class="title-back-bolder mx-0">
											{{ headerCaption }}
										</span>
									</button>
								</b-col>
								<b-col cols="6">
									<div class="header-title-exp-detail text-right" style="border: none">
										<div class="header-date-title pr-3" v-if="!isFilmSingle">
											<div class="wrap-status d-flex justify-content-end">
												<div
													:class="`status ${curExp.statusClass}`"
													style="font-size: 0.825rem; border: 0.005rem solid rgba(226, 230, 235, 0.65)"
												>
													{{ curExp.validatedStatus }}
												</div>
											</div>
										</div>
										<h3 class="my-0" v-else>{{ caption1 }}</h3>
										<div class="d-flex align-items-center justify-content-end w-100 pr-3">
											<span v-if="!isFilmSingle" class="border-encoded p-1"
												>{{ FormMSG(149, 'Encoded by: ') }} <b>{{ encodedBy(curExp) }}</b>
											</span>
										</div>
										<div class="header-name-title pr-3">
											{{ getDateFormated(curExp.date) }} <span class="divider-dot"></span>
											<strong>{{ curExp.amountTotal + ' ' + getCurrency }}</strong>
										</div>
									</div>
								</b-col>
							</b-row>
							<b-card-text class="pt-2">
								<div class="px-1">
									<b-row>
										<b-col cols="12" xl="12">
											<div class="card border-0 p-3">
												<b-row>
													<b-col sm="6">
														<b-form-group :label="FormMSG(645654005, 'Description')" label-for="description">
															<b-form-textarea
																:disabled="true"
																:value="curExp.description"
																type="text"
																id="description"
																rows="3"
															/>
														</b-form-group>
													</b-col>
													<b-col sm="6">
														<b-form-group v-if="curExp.comment.length > 0" :label="FormMSG(4, 'Comment')" label-for="comment">
															<b-form-textarea :disabled="true" id="comment" v-model="curExp.comment" rows="3" />
														</b-form-group>
													</b-col>
												</b-row>
												<b-row class="mt-3">
													<b-col cols="12" xl="12" class="hide-on-desktop">
														<CardListBuilder
															v-if="curExp.type < 2"
															:items="ExpItems"
															style-mode="table"
															:fields="ticketMobileFields"
															:collapsed-fields="ticketCollapseMobileFields"
															:hide-status="isFilmSingle"
														>
															<template slot="actions" slot-scope="{ item, showCollapseDetails }">
																<b-button variant="primary" size="sm" @click="showCollapseDetails(item)">
																	<i class="icon-eye"></i>
																</b-button>
																<b-button
																	variant="success"
																	size="sm"
																	v-if="item.images.length > 0"
																	class="btn bg-transparent border-0"
																	@click="showPicture(item.images)"
																>
																	<component
																		:is="getLucideIcon(ICONS.PICTURE.name)"
																		:color="ICONS.PICTURE.color"
																		:size="20"
																	/>
																</b-button>
															</template>
														</CardListBuilder>
													</b-col>
													<b-col cols="12" xl="12" class="hide-on-tablet">
														<b-table
															v-if="curExp.type < 2"
															:hover="hover"
															responsive
															selectable
															:selectedVariant="selectedColor"
															:select-mode="selectMode"
															:items="ExpItems"
															style="text-align: left"
															:fields="ticketFields"
															:current-page="currentPage"
															sticky-header="800px"
															@row-clicked="rowClicked"
															:per-page="perPage"
															bordered
															small
															:head-variant="hv"
														>
															<template v-slot:cell(validated)="data">
																<div class="wrap-status">
																	<div :class="`status ${data.item.statusClass}`" style="font-size: 0.7rem">
																		{{ data.item.validatedStatus }}
																	</div>
																</div>
															</template>
															<template v-slot:cell(pict)="data">
																<b-button
																	variant="success"
																	v-if="data.item.images.length"
																	size="sm"
																	class="btn bg-transparent border-0"
																	@click="showPicture(data.item.images)"
																>
																	<component
																		:is="getLucideIcon(ICONS.PICTURE.name)"
																		:color="ICONS.PICTURE.color"
																		:size="20"
																	/>
																</b-button>
															</template>
														</b-table>
													</b-col>
													<b-col cols="12" xl="12" class="hide-on-desktop">
														<CardListBuilder
															v-if="curExp.type == 2"
															:items="ExpItems"
															:fields="kmFields"
															@row-clicked="rowClicked"
														/>
													</b-col>
													<b-col cols="12" xl="12" class="hide-on-tablet">
														<b-table
															v-if="curExp.type == 2"
															:hover="hover"
															responsive
															selectable
															:selectedVariant="selectedColor"
															:select-mode="selectMode"
															:items="ExpItems"
															style="text-align: left"
															:fields="kmFields"
															:current-page="currentPage"
															@row-clicked="rowClicked"
															:per-page="perPage"
															bordered
															small
															:head-variant="hv"
														>
															<template v-slot:cell(validatedStatus)="data">
																<div class="wrap-status">
																	<div :class="`status ${data.item.statusClass}`" style="font-size: 0.7rem">
																		{{ data.item.validatedStatus }}
																	</div>
																</div>
															</template>
														</b-table>
													</b-col>
													<b-col cols="12" xl="12" class="hide-on-desktop">
														<!-- for money requests -->
														<CardListBuilder
															v-if="curExp.type == 3"
															:items="ExpItems"
															:fields="moneyRequestFields"
															@row-clicked="rowClicked"
														/>
													</b-col>
													<b-col cols="12" xl="12" class="hide-on-tablet">
														<b-table
															v-if="curExp.type == 3"
															:hover="hover"
															responsive
															selectable
															:selectedVariant="selectedColor"
															:select-mode="selectMode"
															:items="ExpItems"
															style="text-align: left"
															:fields="moneyRequestFields"
															:current-page="currentPage"
															@row-clicked="rowClicked"
															:per-page="perPage"
															bordered
															small
															:head-variant="hv"
														>
															<template v-slot:cell(validatedStatus)="data">
																<div class="wrap-status">
																	<div :class="`status ${data.item.statusClass}`" style="font-size: 0.7rem">
																		{{ data.item.validatedStatus }}
																	</div>
																</div>
															</template>
														</b-table>
													</b-col>
												</b-row>
												<b-row v-if="!isFilmSingle">
													<b-col cols="12">
														<b-row>
															<b-col cols="12">
																<div v-if="$screen.width >= 992">
																	<b-button
																		@click="downloadXls"
																		size="md"
																		variant="primary"
																		:disabled="isLoadingDownload"
																		class="mr-2"
																	>
																		<b-spinner v-show="isLoadingDownload" class="mr-2" small />

																		{{ this.FormMSG(221, 'Download this Expense') }}
																		<component
																			:is="getLucideIcon('DownloadCloud')"
																			:size="16"
																			class="mr-2"
																			v-if="!isLoadingDownload"
																		/>
																	</b-button>
																	<b-button @click="sendXlsByEmail" size="md" variant="primary" :disabled="isLoadingSendMail">
																		<b-spinner v-show="isLoadingSendMail" class="mr-2" small></b-spinner>
																		{{ this.FormMSG(5, 'Send me this Expense by Email') }}
																		<component :is="getLucideIcon('Mail')" :size="16" class="mr-2" />
																	</b-button>
																</div>
																<div v-if="$screen.width < 992">
																	<b-button @click="downloadXls" size="md" variant="primary" class="mr-3" id="btn-download">
																		<i class="cui-cloud-download"></i>
																	</b-button>
																	<b-tooltip target="btn-download" placement="top">
																		{{ FormMSG(221, 'Download this Expense') }}
																	</b-tooltip>
																	<b-button @click="sendXlsByEmail" size="md" variant="primary" id="btn-envelope-closed">
																		<i class="cui-envelope-closed"></i>
																	</b-button>
																	<b-tooltip target="btn-envelope-closed" placement="top">
																		{{ FormMSG(5, 'Send me this Expense by Email') }}
																	</b-tooltip>
																</div>
															</b-col>
														</b-row>
													</b-col>
												</b-row>
												<!-- IF A SINGLE USER -->
												<b-row class="mt-2">
													<b-col cols="12" class="clearfix">
														<div class="float-right">
															<share-information
																v-if="isFilmSingle"
																:btn-label="FormMSG(59, 'Share invoice')"
																:title-modal="FormMSG(60, 'Share the invoice expense to :')"
																:send-a-copy="true"
																:current-expense="curExp"
																:is-send-invoice="true"
																name-modal="modal-share-invoice-expenses"
																icon="fa fa-file-text"
																variant-btn="warning"
																style="margin-right: 5px"
																:isDisabled="!isFilmSinglePaying"
															/>
															<share-information
																v-if="isFilmSingle"
																:btn-label="FormMSG(169, 'Share')"
																:title-modal="FormMSG(170, 'Send this expense sheet to :')"
																:send-a-copy="true"
																:current-expense="curExp"
																name-modal="modal-share-expenses"
															/>
														</div>
													</b-col>
												</b-row>
											</div>
										</b-col>
									</b-row>
								</div>
							</b-card-text>
						</b-card>
					</b-col>
				</b-row>
			</div>
			<!-- <script-export-modal
				v-model="isScriptExpenseReportOpen"
				:items="scripts"
				:custom="customReport"
				export-type="expense"
				@script-export-modal:loading="handleLoading"
				@script-export-modal:closed="expenseRerpotOnClosed"
			/> -->
			<ExpenseService
				:key="expenseModalRefreshToken"
				v-model="isNewExpenseModalOpen"
				:expense-type="editExpense.type"
				:edit-data="editExpense.data"
				@expense-service-main:error="getError"
				@submited="handleModalSubmited"
			/>
		</div>
		<script-export-modal
			v-model="isScriptExpenseReportOpen"
			:items="scripts"
			:custom="customReport"
			export-type="expense"
			@script-export-modal:loading="handleLoading"
			@script-export-modal:closed="expenseRerpotOnClosed"
		/>
		<b-modal
			header-class="header-class-modal-doc-package"
			:title="FormMSG(21211, 'Success!')"
			class="modal-success"
			v-model="successModal"
			@ok="successModal = false"
			ok-variant="success"
			ok-only
		>
			{{ FormMSG(6, 'The expense has been sent to your Email address') }}
		</b-modal>
		<b-modal
			header-class="header-class-modal-doc-package"
			:title="FormMSG(21212, 'Expense item comment')"
			class="modal-danger"
			v-model="ModalExpenseItemComment"
			@ok="ModalExpenseItemComment = false"
			ok-variant="danger"
			ok-only
		>
			<b-row>
				<b-col cols="12" sm="12">
					<b-form-group :label="FormMSG(51511, 'Comment')" label-for="comment" :label-cols="2">
						<b-form-textarea :disabled="true" id="comment" v-model="expenseItemComment" rows="2"></b-form-textarea>
					</b-form-group>
				</b-col>
			</b-row>
		</b-modal>
	</errorContainer>
</template>

<script>
// import axios from 'axios';
import Loading from 'vue-loading-overlay';
import { rendCurrency, rendKgCo2, formatTableDate } from '~helpers';
import mapProps from '@/shared/vuePropsMapper';
import { getFileExtension } from '@/shared/helpers';
import { isNil, formatFileName } from '~utils';
import { store } from '@/store';
import languageMessages from '@/mixins/languageMessages';
import ExpenseService from '@/components/ExpenseService/Main';
import { getTextFromMenuNumberAndMenuValue } from '@/cruds/language.crud';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import ShareInformation from '@/components/InfoSheet/ShareInformation';
import {
	ChevronDown,
	ChevronUp,
	Sprout,
	FileText,
	Ticket,
	Car,
	ArrowLeft,
	Image as Photo,
	Edit,
	MessageCircle,
	Download,
	Send,
	Mail,
	AlertCircle
} from 'lucide-vue';
import moment from 'moment';
import ExpenseDetailFooter from '@/components/MyExpenseMobile/ExpenseDetailFooter';
import { mapActions } from 'vuex';
import globalMixin from '@/mixins/global.mixin';
import { recalculateDistanceValueByUnit } from '@/shared/google/helpers';
import * as icons from 'lucide-vue';
import { getScripts } from '@/cruds/script.crud';
import { expenseReport } from '@/cruds/export.crud';
import ScriptExportModal from '@/components/Export/ScriptExportModal';
import CurrencySvg from '@/components/Svg/Currency';
import _ from 'lodash';
import { FILENAME_REPORT, OUTPUT_FORMAT_EXPORT } from '@/shared/constants';

export default {
	name: 'ExpenseDetails',
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {
		...mapProps(['hover', 'striped', 'bordered'], {
			type: Boolean,
			default: true
		}),
		...mapProps(['small', 'fixed'], {
			type: Boolean,
			default: false
		})
	},
	components: {
		CurrencySvg,
		Loading,
		ExpenseService,
		ShareInformation,
		ChevronDown,
		ChevronUp,
		Sprout,
		FileText,
		Ticket,
		Car,
		ArrowLeft,
		ExpenseDetailFooter,
		Photo,
		Edit,
		MessageCircle,
		Download,
		Send,
		Mail,
		AlertCircle,
		ScriptExportModal
	},
	data() {
		const currency = store.state.myProfile.currencySymbol;
		return {
			fileDownloadLink: null,
			getCurrency: currency,
			editExpense: {
				expenseRef: null,
				modalOpen: false,
				type: 0,
				data: {}
			},

			hv: 'dark',
			Validated: 0,
			erreur: {},
			curMonthStr: '',
			oneItem: '',
			currentPage: 1,
			perPage: 0,
			warning: '',
			successModal: false,
			ModalExpenseItemComment: false,
			expenseItemComment: '',
			filter: '',
			isLoading: false,
			isLoadingDownload: false,
			isLoadingSendMail: false,
			timeZoneStr: '',
			timeZoneHourDif: 0,
			curDay: {
				id: 0,
				date: '',
				strTime: 'T08:00:00Z',
				endTime: 'T18:00:00Z',
				totMin: 0,
				lunchStrTime: 'T00:00:00Z',
				lunchEndTime: 'T00:00:00Z',
				lunch: false,
				hotel: false,
				stageDayUsed: false,
				validated: 0,
				validatedStatus: 'Not Submited'
			},
			newCurDay: {
				id: 0,
				date: '2019-09-01',
				strTime: 'T08:00:00Z',
				endTime: 'T18:00:00Z',
				totMin: 0,
				lunchStrTime: 'T00:00:00Z',
				lunchEndTime: 'T00:00:00Z',
				lunch: false,
				hotel: false,
				stageDayUsed: false,
				validated: 0,
				validatedStatus: 'Not Submited'
			},
			toggleDetail: true,
			canReadComment: false,
			expenseModalRefreshToken: 0,
			isNewExpenseModalOpen: false,
			selectMode: 'single',
			selectedColor: 'primary',
			isScriptExpenseReportOpen: false,
			scriptTypeId: 0,
			scripts: [],
			customReport: null
		};
	},
	async created() {
		if (this.curDay.date.length === 0) {
			this.firstTimeInitialisation();
		}
		await this.reloadData();

		const { s } = this.$route.query;
		// s === 0 => update
		// s === 1 => delete
		if (!isNil(s) && s === '0') {
			this.createToastForMobile(this.FormMSG(22, 'Success'), this.FormMSG(23, 'Expense updated successfully'), '');
		}

		if (
			(isNil(this.currentExpenseMobile) || (this.currentExpenseMobile && parseInt(this.currentExpenseMobile.id) === 0) || isNil(this.ExpItems)) &&
			this.$screen.width <= 576 // mobile only
		) {
			this.goBack();
		}
	},
	computed: {
		headerCaption() {
			if (this.curExp.type === 2) {
				return `${this.FormMSG(458, 'Expense details')} ${this.FormMSG(451, 'for Travel')} #${this.curExp.id}: ${this.curExp.user.name} ${
					this.curExp.user.firstName
				}`;
			}
			return `${this.FormMSG(454, 'Expense details')} #${this.curExp.id}: ${this.curExp.user.name} ${this.curExp.user.firstName}`;
		},
		curExp: {
			get() {
				return this.$store.getters['expenses/focusedExpenseItem'];
			},
			set(expense) {
				this.$store.dispatch('expenses/setFocusExpenseItem', expense);
			}
		},
		currentExpenseMobile: {
			get() {
				return this.$store.getters['expenses/getCurrentExpenseMobile'];
			},
			set(expense) {
				this.$store.dispatch('expenses/setCurrentExpenseMobile', expense);
			}
		},
		ExpItems() {
			return this.$store.getters['expenses/currentExpense'];
		},
		caption1() {
			const _t = this.FormMSG(323231, 'Expense details for');
			const { id, typeString } = this.curExp;
			return `${typeString} #${id}`;
		},
		styleObject() {
			const myObj = {
				color: '#323635',
				fontSize: '14px'
			};
			const { validated } = this.curExp;
			if (validated == 1) {
				myObj.color = '#0F4831';
			} else if (validated == 2) {
				myObj.color = '#CE6F35';
			} else if (validated == 8) {
				myObj.color = '#659B2E';
			} else if (validated == 4 || validated == 16) {
				myObj.color = '#B73A41';
			}
			return myObj;
		},
		moneyRequestFields() {
			return [
				{
					key: 'id',
					label: 'Id',
					formatter: (value) => {
						return '#' + value;
					},
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'validatedStatus',
					label: this.FormMSG(645654003, 'Status'),
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'date',
					label: this.FormMSG(645654004, 'Date'),
					formatter: (value) => value,
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'description',
					label: this.FormMSG(645654005, 'Description'),
					sortable: true
				},
				{
					key: 'amountTotal',
					label: this.FormMSG(645654001, 'Amount'),
					formatter: (value) => rendCurrency(value),
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'paiementTypeName',
					label: this.FormMSG(645654002, 'Paid'),
					sortable: false,
					class: 'text-center'
				}
			];
		},
		moneyRequestMobileFields() {
			const keys = ['date', 'kgCoTwo', 'validatedStatus', 'amountTotal'];
			return this.moneyRequestFields.filter((t) => !keys.includes(t.key));
		},
		baseTicketFields() {
			return [
				{
					key: 'id',
					label: '#Id',
					formatter: (v) => `${v}`,
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'validated',
					label: this.FormMSG(746554009, 'Status'),
					sortable: false,
					formatter: (value) => {
						return this.GetTextFromMenuNumberAndMenuValue(1008, value);
					},
					class: 'text-center'
				},
				{
					key: 'date',
					label: this.FormMSG(746554002, 'Date'),
					formatter: (v) => formatTableDate(v),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'description',
					label: this.FormMSG(746554003, 'Description'),
					sortable: true
				},
				{
					key: 'departmentName',
					label: this.FormMSG(746554004, 'Department'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'categoryName',
					label: this.FormMSG(746554005, 'Category'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'supplierName',
					label: this.FormMSG(746554006, 'Supplier'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'kgCoTwo',
					label: 'CO2',
					formatter: (v) => rendKgCo2(v),
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'amountTotal',
					label: this.FormMSG(746554007, 'Amount'),
					formatter: (v) => rendCurrency(v),
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'amountVat',
					label: this.FormMSG(24, 'VAT'),
					formatter: (v) => rendCurrency(v),
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'paiementTypeName',
					label: this.FormMSG(746554008, 'Paid'),
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'pict',
					label: this.FormMSG(746554010, 'Picture'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
		},
		ticketFields() {
			if (this.isFilmSingle) {
				const keys = ['validated'];
				return this.baseTicketFields.filter((t) => !keys.includes(t.key));
			} else {
				return this.baseTicketFields;
			}
		},
		ticketMobileFields() {
			return [
				{
					key: 'description',
					label: this.FormMSG(746554003, 'Description'),
					sortable: true
				},
				{
					key: 'date',
					label: this.FormMSG(746554002, 'Date'),
					formatter: (v) => {
						return moment(v).format(`DD/MM/YYYY`);
					},
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'amountTotal',
					label: this.FormMSG(746554007, 'Amount'),
					formatter: (v) => rendCurrency(v),
					sortable: false,
					class: 'text-right'
				},
				{
					key: 'kgCoTwo',
					label: 'CO2',
					formatter: (v) => rendKgCo2(v),
					sortable: true,
					class: 'text-right'
				}
			];
		},
		ticketCollapseMobileFields() {
			const keys = ['date', 'kgCoTwo', 'amountTotal', 'validated', 'pict'];
			return this.baseTicketFields.filter((t) => !keys.includes(t.key));
		},
		kmFields() {
			if (this.isFilmSingle) {
				const keys = ['validatedStatus'];
				return this.kmFieldsBase.filter((t) => !keys.includes(t.key));
			} else {
				return this.kmFieldsBase;
			}
		},
		kmMobileFields() {
			const keys = ['date', 'kgCoTwo', 'validatedStatus', 'amountTotal'];
			return this.kmFieldsBase.filter((t) => !keys.includes(t.key));
		},
		kmFieldsBase() {
			return [
				{
					key: 'id',
					label: '#Id',
					formatter: (value) => `${value}`,
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'validatedStatus',
					label: this.FormMSG(846554005, 'Status'),
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'description',
					label: this.FormMSG(746554003, 'Description'),
					sortable: true
				},
				{
					key: 'date',
					label: 'Date',
					label: this.FormMSG(846554001, 'Date'),
					formatter: (value) => formatTableDate(value),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'fromLocation',
					label: this.FormMSG(846554002, 'From'),
					sortable: true
				},
				{
					key: 'toLocation',
					label: this.FormMSG(846554003, 'To'),
					sortable: true
				},
				{
					key: 'subCategoryName',
					label: this.FormMSG(11, 'Transportation mean'),
					sortable: true
				},
				{
					key: 'km',
					label: this.FormMSG(12, 'Distance'),
					formatter: (value) => `${recalculateDistanceValueByUnit(value)} ${store.state.myProfile.distanceUnit}`,
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'kgCoTwo',
					label: 'CO2',
					formatter: (v) => rendKgCo2(v),
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'amountTotal',
					label: this.FormMSG(846554004, 'Amount'),
					formatter: (value) => rendCurrency(value),
					sortable: true,
					class: 'text-right'
				}
			];
		},
		isProd() {
			return store.isProd();
		},
		typeMobileFields() {
			if (this.currentExpenseMobile.type < 2) {
				return this.ticketCollapseMobileFields;
			} else if (this.currentExpenseMobile.type == 2) {
				return this.kmMobileFields;
			} else if (this.currentExpenseMobile.type == 3) {
				return this.moneyRequestMobileFields;
			}
		}
	},
	methods: {
		...mapActions({
			setFocusExpenseItem: 'expenses/setFocusExpenseItem',
			handleSetTabActived: 'expenses/setActiveTab',
			setCurrentExpenseId: 'expenses/setCurrentExpenseId'
		}),
		encodedBy(data) {
			return `${data.encoder.firstName} ${data.encoder.name}`;
		},
		async handleModalSubmited() {
			this.expenseModalRefreshToken = this.expenseModalRefreshToken + 1;

			this.$toast({
				message: this.FormMSG(23, 'Your expense was updated successfully.'),
				title: this.FormMSG(22, 'Success'),
				variant: 'success'
			});

			this.isNewExpenseModalOpen = false;
			await this.reloadData();
		},
		rowClicked(item) {
			if (item.type === 2) {
				item.originalKm = item.km;
				item.originalFromLocation = item.fromLocation;
				item.originalToLocation = item.toLocation;
			}

			if (this.isFilmSingle || this.isProd) {
				this.getExpenseDetails(item);
				this.expenseItemComment = item.comment;
			} else {
				if (item.validated !== 0) return;
				this.getExpenseDetails(item);
				if (item.comment.length > 0) {
					this.expenseItemComment = item.comment;
					this.ModalExpenseItemComment = true;
				}
			}

			this.isNewExpenseModalOpen = true;
		},
		/**
		 * @param {Array} images
		 */
		showPicture(images) {
			if (isNil(images)) return;

			const list = images.map((img) => ({
				xid: img,
				src: `${process.env.VUE_APP_GQL}/images/${img}`,
				thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img,
				ext: getFileExtension(img)
			}));

			this.$previewImages({
				images: list,
				focusIndex: 0,
				options: {
					hideLikeDislike: true,
					hideCommentButton: true,
					hideDisLikeButton: true,
					presentationMode: 'doc'
				}
			});
		},
		showEditExpenseMobile(item) {
			if (this.isFilmSingle || this.isProd) {
				return true;
			} else {
				if (item.validated !== 0) {
					this.canReadComment = !!((item.validated === 4 || item.validated === 16) && item.comment.trim().length > 0);
					return false;
				}
				return true;
			}
		},
		/**
		 * @param {Int} id - ExpenseItem.expenseId
		 * @return {Object}
		 */
		getExpenseDetails(item) {
			this.editExpense = {
				expenseRef: item.id,
				modalOpen: true,
				type: item.type,
				data: item
			};
		},
		getCurrentDate() {
			var currentDateWithFormat = new Date().toJSON().slice(0, 10);
			return currentDateWithFormat;
		},
		firstTimeInitialisation() {
			this.setTimeZoneStr();
		},
		goBack() {
			let home = window.location.href.split`#/`[1].split`/`[0];
			const path = `/${home}`;
			return this.$router.push({
				path: path,
				query: {
					activeTab: this.$route.query.previousActiveTab
				}
			});
			// const path = '/myexpenses?activeSubmittedTab=true';
			// this.$router.push({ path });
		},
		updateTimeToTZ(timeString) {
			this.setTimeZoneStr();
			if (timeString.length == 0) {
				return timeString;
			}
			// check if already converted
			//  console.log("in updateTimeToTZ  RECEIVING:",timeString );
			//console.log("substring 9=",timeString.substring(9) );
			//console.log("in updateTimeToTZ timeZoneStr=",this.timeZoneStr);

			if (timeString.substring(9) != this.timeZoneStr) {
				// needs conversion
				//console.log("in updateTimeToTZ inval=",timeString)
				// extract time value
				var timStr = timeString.substring(1, 3);
				var hGmt = parseInt(timStr, 10);
				var h = (hGmt + this.timeZoneHourDif) % 24;
				if (h > 9) {
					var retval = 'T' + h + timeString.substring(3);
				} else {
					var retval = 'T0' + h + timeString.substring(3);
				}
				//    console.log("in updateTimeToTZ retval=",retval);
				retval = retval.substring(0, 9) + this.timeZoneStr;
				//    console.log("in updateTimeToTZ retval new TZ=",retval);
				return retval;
			} else {
				// does not need conversion
				var retval = timeString;
				//    console.log("in updateTimeToTZ retval kept existing conversion=",retval);
				return retval;
			}
		},
		updateTZdataEach(expItemData) {
			// setting row color
			expItemData.validatedStatus = this.validatedText(expItemData.validated);
			// https://bootstrap-vue.js.org/docs/reference/color-variants
			expItemData._rowVariant = this.validatedColor(expItemData.validated);
			expItemData.date = expItemData.date.substring(0, 10) + this.updateTimeToTZ(expItemData.date.substring(10));
		},
		setTimeZoneStr() {
			// getting timezone
			if (this.timeZoneStr.length > 0) {
				return;
			}
			var d = new Date();
			var n = d.getTimezoneOffset();
			if (n < 0) {
				var hOffset = -n / 60;
				var sign = '+';
			} else {
				var hOffset = n / 60;
				var sign = '-';
			}
			var BASE = '00';
			this.timeZoneStr = hOffset ? sign + BASE.substr(0, 2 - Math.ceil(hOffset / 10)) + hOffset : sign + BASE;
			this.timeZoneStr = this.timeZoneStr + ':00';
			this.timeZoneHourDif = hOffset;
		},
		validatedText(validated) {
			// console.log('heelooooo');
			// returns the label text corresponding to the validated value
			return getTextFromMenuNumberAndMenuValue(1008, validated);
		},
		validatedColor(validated) {
			if (validated == 1) {
				var retval = 'primary';
			} else if (validated == 2) {
				var retval = 'warning';
			} else if (validated == 8) {
				var retval = 'success';
			} else if (validated == 4 || validated == 16) {
				var retval = 'danger';
			} else {
				var retval = 'grey';
			}
			return retval;
		},
		async reloadData() {
			// this.isLoading = true;
			const ExpenseId = parseInt(this.$route.params.id, 10);
			await this.$store.dispatch('expenses/getCurExpense', ExpenseId);
			// this.isLoading = false;
		},
		async sendXlsByEmail() {
			this.isLoadingSendMail = true;
			this.warning = '';
			this.scripts = await getScripts(store.state.myProject.licenseID, store.state.myProject.id, this.scriptTypeId, true);
			const id = +(this.$isPwa() || this.$screen.width <= 576 ? this.currentExpenseMobile.id : this.curExp.id);

			if (this.scripts.length === 0) {
				await this.manageExpenseReport(id, 0, true).then(() => {
					this.createToastForMobile(this.FormMSG(22, 'Success'), this.FormMSG(476, 'The document has been sent to your email address.'));
				});
			} else if (this.scripts.length === 1) {
				await this.manageExpenseReport(id, { id: this.scripts[0].id }, true).then(() => {
					this.createToastForMobile(this.FormMSG(22, 'Success'), this.FormMSG(476, 'The document has been sent to your email address.'));
				});
			} else {
				this.isScriptExpenseReportOpen = true;
				this.customReport = {
					subjectId: id,
					sendEmail: true
				};
			}
		},
		async downloadXls() {
			this.isLoadingDownload = true;
			this.warning = '';
			this.scripts = await getScripts(store.state.myProject.licenseID, store.state.myProject.id, this.scriptTypeId, true);
			const id = +(this.$isPwa() || this.$screen.width <= 576 ? this.currentExpenseMobile.id : this.curExp.id);

			if (this.scripts.length === 0) {
				await this.manageExpenseReport(id).finally(() => {
					this.isLoadingDownload = false;
				});
			} else if (this.scripts.length === 1) {
				await this.manageExpenseReport(id, {
					id: this.scripts[0].id,
					fileName: this.scripts[0].customExportFileName,
					ext: this.getExtensionScript(
						OUTPUT_FORMAT_EXPORT.at(this.scripts[0].outputFileFormat),
						this.scripts[0].customExtension,
						getFileExtension(this.scripts[0].fileName)
					)
				}).finally(() => {
					this.isLoadingDownload = false;
				});
			} else {
				this.isScriptExpenseReportOpen = true;
				this.customReport = {
					subjectId: id,
					sendEmail: false
				};
			}
		},
		async manageExpenseReport(subjectId, script = { id: 0, fileName: null, ext: 'xlsx' }, sendEmail = false) {
			let fileName = this.getFileNameScript(FILENAME_REPORT.REPORT_EXPENSE, script.id, script.fileName);

			await expenseReport(
				{
					subjectId,
					sendEmail
				},
				script.id
			)
				.then((result) => {
					if (!sendEmail) {
						this.forceDownload(result, fileName + '.' + script.ext);
					}
				})
				.catch((e) => {
					console.error(e);
				})
				.finally(() => (this.isLoadingSendMail = false));
		},
		convertToCurrency(amount) {
			return rendCurrency(amount);
		},
		dateToLiteral(expenseDate) {
			const langCurrent = this.currentLang;
			const fullLiteralDate = moment(expenseDate).locale(`${langCurrent}`).format(`dddd, Do MMMM YYYY`);

			return fullLiteralDate;
		},
		convertToCo2(kgCo) {
			return rendKgCo2(kgCo);
		},
		editExpenseMobile(item) {
			const { id } = this.$route.params;
			this.setFocusExpenseItem(item);
			this.handleSetTabActived(1);
			// this.setCurrentExpenseId(id);
			let type = item.type;
			let form = 0;
			if (item.type === 0 || item.type === 1) {
				form = 2;
			} else if (item.type === 2) {
				form = 1;
				item.originalKm = item.km;
				item.originalFromLocation = item.fromLocation;
				item.originalToLocation = item.toLocation;
			}
			const path = `/myexpenses/${form}/u/${type}/${parseInt(item.id, 10)}?back=e&id=${id}`;
			this.$router.push({ path });
		},
		toggleExpenseDetail(payload) {
			this.toggleDetail = !payload;
		},
		editComment(comment) {
			// console.log({comment});
			this.expenseItemComment = comment;
			this.ModalExpenseItemComment = true;
		},
		showExpenseTitle(expense) {
			return expense.description && expense.description.replace(/[ \s]+/g, '') !== '' && expense.description.replace(/[ \s]+/g, '') !== `#${expense.id}`;
		},
		backToExpense() {
			if (this.$route.query.fromBudget) {
				this.$router.replace({
					path: '/budget',
					query: {
						activeTabExpenseSheet: true
					}
				});

				return;
			}
			if (this.$route.query.previousActiveTab) {
				let home = window.location.href.split`#/`[1].split`/`[0];
				const path = `/${home}`;
				return this.$router.push({
					path: path,
					query: {
						activeTab: this.$route.query.previousActiveTab
					}
				});
			}

			const path = `/myexpenses`;

			this.$router.push({ path });
		},
		getDateFormated(date) {
			return moment(date).format('DD/MM/YYYY');
		},
		getLucideIcon(name) {
			return icons[name];
		},
		handleLoading(payload) {
			if (payload) {
				this.isLoadingSendMail = false;
				this.isLoadingDownload = false;
			}
		},
		expenseRerpotOnClosed() {
			this.isScriptExpenseReportOpen = false;
			this.isLoadingSendMail = false;
			this.isLoadingDownload = false;
		},
		getError(payload) {
			if (!_.isNil(payload) && !_.isNil(payload.status) && payload.status === 509) {
				this.isNewExpenseModalOpen = true;
			}
		}
	}
};
</script>
<style lang="scss" scoped>
input[type='text'] {
	width: 100%;
	padding: 12px 20px;
	margin: 4px 0;
	box-sizing: border-box;
	border: none;
	/*background-color: grey;
      color: white;*/
}
.border-encoded {
	border: 2px solid #cad0d7;
	border-radius: 5px;
}
</style>
